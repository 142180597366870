import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import reportWebVitals from "./reportWebVitals";
import { configure } from "mobx"
import MergeJourneyApp from './MergeJourneyApp';
// import Header from './app/layout/Header/Header';
import { applyMiddleware, createStore } from 'redux';
import reduxPromise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import rootReducer from './app/redux/reducers';
import {Provider} from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
configure({
  useProxies: "ifavailable"
})

export const history = createBrowserHistory();
const composedEnhancer = composeWithDevTools(
  applyMiddleware(reduxPromise,
    thunk)
  // applyMiddleware(reduxPromise)
)
var store = createStore(rootReducer, {}, composedEnhancer);
ReactDOM.render(
  <React.StrictMode>
   
    <Router history={history}>
      <div className="content-holder bt-container">
        <Provider store={store}>
        <App />
        </Provider>
      </div>
    </Router>
   
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

declare global {
  interface Window {
    updateDecibelTracking?(path: string): void // Make it optional
    Decibel_Switch: string
  }
 }
