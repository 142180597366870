import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import Constants from "../../models/constants";
import {
  ValidateProfileApiResponse,
  ClientCharacteristicApiBody,
  ClientCharacteristicApiResponse,
  TriggerKCIResponse,
  UpdatePasswordApiBody,
  UpdatePasswordApiResponse,
  UserNameResponse,
  SendVerificationMailResponse,
  ValidateSessionApiResponse,
} from "../../models/UserNameResponse";

import { RequestProfileWithDetails } from "../../models/UserNameResponse";
import { store } from "../../stores/store";
import httpClient from "../agent";

import { getAuthToken } from "../authToken";

const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_AccountRecoveryAPIBaseURL;
instance.interceptors.request.use(async (config : any) => {

  if (config.url == "/oauth/accesstoken") return config;
  const token = await getAuthToken();
  if (config.journey !== undefined && config.journey.includes("selfRegistration")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "UserName" : config.userName,
    };
  }
  else  if (config.journey !== undefined && config.journey.includes("SAF")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "UUID" : config.UUID,
    };
  }
  else config.headers = {
    "APIGW-Tracking-Header": uuidv4(),
    "Content-Type": "application/json",
    "asp-session-id": store.commonStore.SessionId
      ? store.commonStore.SessionId
      : "",
    "reset-password-id": store.commonStore.ResetPasswordId
      ? store.commonStore.ResetPasswordId
      : "",
  };
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(
  async (response) => {
    //await sleep(1000);
    store.commonStore.setAPIError("APIERROR", "");
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;

    store.commonStore.setAPIError("APIERROR", Constants["APIERRORMESSAGE"]);
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors?.hasOwnProperty("id")) {
          //  history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          //  toast.error(data);
        }
        break;

      case 401:
        //   toast.error("unauthorized");
        break;

      case 404:
        // history.push("/not-found");

        break;

      case 500:
        //    history.push("/server-error");
        break;
    }

    return Promise.reject(error);
  }
);

const mergeRecoveryJourney = {
  triggerKci: () => httpClient(instance).get<TriggerKCIResponse>("/triggerKCI"),

  sendVerificationMail: (data: Object) =>
    httpClient(instance).getData<SendVerificationMailResponse>(
      "/email-verification",data
    ),

  validateProfile: (UserNameKey: string) =>
    httpClient(instance).get<ValidateProfileApiResponse>(
      `${instance.defaults.baseURL}/profile/${UserNameKey}`
    ),

  getProfiles: (requestProfile: RequestProfileWithDetails) =>
    httpClient(instance).post<UserNameResponse>("/profile", requestProfile),

  validateClientCharacteristic: (
    clientCharacteristicApiBody: ClientCharacteristicApiBody
  ) =>
    httpClient(instance).post<ClientCharacteristicApiResponse>(
      "/client-characteristic",
      clientCharacteristicApiBody
    ),

  updatePassword: (updatePasswordApiBody: UpdatePasswordApiBody) =>
    httpClient(instance).post<UpdatePasswordApiResponse>(
      "/update-password",
      updatePasswordApiBody
    ),

  // validate session API

  validateSession: () =>
    httpClient(instance).get<ValidateSessionApiResponse>("/session-validation"),
};

/* const httpClient = {
  get: <T>(url: string) => instance.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
  instance.post<T>(url, body).then(responseBody),

  put: <T>(url: string, body: {}) => instance.put<T>(url, body).then(responseBody),

  del: <T>(url: string) => instance.delete<T>(url).then(responseBody),
}; */

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export default mergeRecoveryJourney;
