import axios, { AxiosError, AxiosResponse } from "axios";
import { Agent } from "http";
import { runInAction } from "mobx";
import {
  AuthRequestApiResponse,
  AuthRequestBody,
  GetaccesstokenvordelResponseForAssisted,
} from "../models/assistedJourneyModels/AssistedJourneyModel";
import { store } from "../stores/store";
import httpClient from "./agent";

import {GetaccesstokenvordelResponse} from './../models/UpdateContactModels/UpdateContactModel';
import { GetaccesstokenvordelResponseForSelfReg } from
  './../models/SelfRegistrationModels/SelfRegistrationModels';

const getAuthToken = async (): Promise<string> => {
  const token = store.commonStore.token;
  const tokenExpiry = store.commonStore.tokenExpiry;
  const tokenValid = tokenExpiry && tokenExpiry > new Date();
  if (token && tokenValid) return token;
  const baseApiURL = process.env.REACT_APP_AuthrisationURL;
  const auth: {
    accessToken: string;
    tokenType: string;
    expiresIn: string;
  } = (
    await axios.request({
      url: "/oauth/accesstoken",
      method: "get",
      baseURL: baseApiURL,
      params: {
        grant_type: "client_credentials",
      },
      headers: {
        Authorization: "Basic " + process.env.REACT_APP_AuthrisationToken,
        "Content-type": "Application/json",
      },
    })
  ).data;
  let today = new Date();
  today.setHours(today.getHours() + 20);
  runInAction(() => {
    store.commonStore.token = auth.accessToken;
    store.commonStore.tokenExpiry = today;
  });
  return auth.accessToken;
};

const getAuthTokenForUpdateContact = async (): Promise<GetaccesstokenvordelResponse> => {
  const instance = axios.create();
  // instance.defaults.baseURL = process.env.REACT_App_DashBoardUrl;
  // const token = store.commonStore.token;
  // const tokenExpiry = store.commonStore.tokenExpiry;
  // const tokenValid = tokenExpiry && tokenExpiry > new Date();
  // if (token && tokenValid) return token;
  const auth: {
    accessToken: string;

    deviceID: string;

    masterContactID: string;

    userID: string;

    contactID: string;
  } = (
    await instance.request({
      url: process.env.REACT_APP_AccessTokenUrl,
      method: "get",

      headers: {
        "Content-type": "application/json",
      },
    })
  ).data;
  // let today = new Date();
  // today.setHours(today.getHours() + 2);
  // runInAction(() => {
  //   store.commonStore.token = auth.accessToken;
  //   store.commonStore.tokenExpiry = today;
  // });
  return auth;
};

const getAuthTokenForSelfReg = async (): Promise<GetaccesstokenvordelResponseForSelfReg> => {
  const instance = axios.create();
  const client_id : any = process.env.REACT_APP_SelfRegistration_APIGW_CLIENTID;
  const client_secret : any = process.env.REACT_APP_SelfRegistration_APIGW_CLIENT_SECRET
  const auth: {
    accessToken: string;
    tokenType: string;
    expiresIn: number;
  } = (
    await instance.request({
      url: "/oauth/accesstoken",
      method: "get",
      baseURL: process.env.REACT_APP_AuthrisationURL,
      auth: {
        username: client_id, //client_id
        password: client_secret//client_secret
      },
      params: {
        grant_type: "client_credentials",
      },
      headers: {
        Authorization: "Basic " + process.env.REACT_APP_AuthrisationToken
      },
    })
  ).data;
  return auth;
};

const getAuthTokenForAssistedJourney = async (): Promise<GetaccesstokenvordelResponseForAssisted> => {
  const instance = axios.create();
  if (window.location.hostname.includes("eric1-dmze2e-ukb.bt.com")) {
    instance.defaults.baseURL = process.env.REACT_App_BrowserStackDashBoardUrl;
  } else {
    instance.defaults.baseURL = process.env.REACT_App_DashBoardUrl;
  }
 
  const auth: {
    accessToken: string;

    deviceID: string;

    masterContactID: string;

    userID: string;

    contactID: string;
  } = (
    await instance.request({
      url: process.env.REACT_APP_AccessTokenUrl,
      method: "get",

      headers: {
        "Content-type": "application/json",
      },
    })
  ).data;
  // let today = new Date();
  // today.setHours(today.getHours() + 2);
  // runInAction(() => {
  //   store.commonStore.token = auth.accessToken;
  //   store.commonStore.tokenExpiry = today;
  // });
  return auth;
};

export { getAuthToken, getAuthTokenForUpdateContact,
  getAuthTokenForAssistedJourney, getAuthTokenForSelfReg };
