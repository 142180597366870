import axios, { AxiosError } from "axios";
import { ValidateEmailRequest, ValidateEmailApiResponse, ValidateOrderNumberApiResponse,
  ValidateBACApiResponse, ValidateBillRefApiResponse, CreatePINApiBody, CreatePINApiResponse,
  ValidatePINApiBody, ValidatePINApiResponse, RegisterUserResponse, RegisterUserRequest,
  PaperFreeFlagApiResponse } from "../../models/SelfRegistrationModels/SelfRegistrationModels";
import httpClient from "../agent";
import { store } from "../../stores/store";

import { getAuthTokenForSelfReg } from "../authToken";

const instance = axios.create();
const pinInstance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_RegistrationAPIBaseURL;
pinInstance.defaults.baseURL = process.env.REACT_APP_PINAPIBaseURL;

// Request Interceptor
instance.interceptors.request.use(async (config : any) => {
  if (config.url === "/oauth/accesstoken") return config;
  const token = await getAuthTokenForSelfReg();
  if(config.url?.includes("validate-email")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "Recaptcha-Response-Token": config.token,
    };
  }
  else if(config.url?.includes("validate-billing-account")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "BillingAccountNumber" : config.accountNumber.toUpperCase(),
      "asp-session-id": store.commonStore.SessionId ? store.commonStore.SessionId : "",
    };
  }
  else if(config.url?.includes("validate-order-number")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "OrderNumber": config.orderNumber.toUpperCase(),
      "asp-session-id": store.commonStore.SessionId ? store.commonStore.SessionId : "",
    };
  }
  else if(config.url?.includes("validate-billing-reference")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "BillingAccountNumber": config.accountNumber.toUpperCase(),
      "BillReference": config.billRef.toUpperCase(),
      "asp-session-id": store.commonStore.SessionId ? store.commonStore.SessionId : "",
    };
  }  
  else if(config.url?.includes("user-details")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "asp-session-id": store.commonStore.SessionId ? store.commonStore.SessionId : "",
      "Recaptcha-Response-Token": config.token,
    };
  }
  else if(config.url?.includes("verify-email-kci")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "journeyType": config.journeyType,
    };
    if (config.journeyType === 1) {
      config.headers.profileActivationCode = config.activationCode;
    } else {
      config.headers.bacId = config.bacId;
    }
  }
  else
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
    };
  if (token) config.headers.Authorization = `Bearer ${token.accessToken}`;
  return config;
});

pinInstance.interceptors.request.use(async (config : any) => {
  if (config.url === "/oauth/accesstoken") return config;
  const token = await getAuthTokenForSelfReg();
  if (config.url?.includes("validate-pin")) {
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "JourneyType": "React",
    };
  }
  else  
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
    };
  if (token) config.headers.Authorization = `Bearer ${token.accessToken}`;
  return config;
});

// Response Interceptor
instance.interceptors.response.use(
  async (response: any) => {
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors?.hasOwnProperty("id")) {
          //  history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
                modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          //  toast.error(data);
        }
        break;

      case 401:
        //   toast.error("unauthorized");
        break;

      case 404:
        // history.push("/not-found");
        break;

      case 500:
        //    history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

pinInstance.interceptors.response.use(
  async (response: any) => {
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors?.hasOwnProperty("id")) {
          //  history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
                modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          //  toast.error(data);
        }
        break;

      case 401:
        //   toast.error("unauthorized");
        break;

      case 404:
        // history.push("/not-found");
        break;

      case 500:
        //    history.push("/server-error");
        break;
    }
    return Promise.reject(error);
  }
);

const selfRegistration = {
  validateEmail: (validateEmailApiBody: ValidateEmailRequest,token : object) =>
  httpClient(instance).postData<ValidateEmailApiResponse>("/validate-email",validateEmailApiBody,token),

  validateBAC: (data: {}) =>
  httpClient(instance).getData<ValidateBACApiResponse>("/validate-billing-account",data),

  validateOrderNumber: (data: {}) =>
  httpClient(instance).getData<ValidateOrderNumberApiResponse>("/validate-order-number",data),

  validateBillRef: (data: {}) =>
  httpClient(instance).getData<ValidateBillRefApiResponse>("/validate-billing-reference",data),

  credentialCreatePin: (
    createPINApiBody: CreatePINApiBody
  ) =>
    httpClient(pinInstance).post<CreatePINApiResponse>(
      "/create-pin",
      createPINApiBody
    ),

  validatePin: (
    validatePINApiBody: ValidatePINApiBody
  ) =>
    httpClient(pinInstance).post<ValidatePINApiResponse>(
      "/validate-pin",
      validatePINApiBody
    ),

  registerUser: (
    registerUserApiBody: RegisterUserRequest,
    token : object
  ) =>
    httpClient(instance).postData<RegisterUserResponse>(
      "/user-details",
      registerUserApiBody,
      token
    ),

  getPaperFreeFlag: (data: {}) =>
    httpClient(instance).getData<PaperFreeFlagApiResponse>("/verify-email-kci", data),

   getPaperFreeBilling: (data: {}) =>
     httpClient(instance).getData<PaperFreeFlagApiResponse>("/verify-email-kci", data)
};

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default selfRegistration;