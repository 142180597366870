import React from "react";
import axios from "axios";
import { getAuthTokenForRepairCenter } from "../Authtoken/authTokenRepairCentre";
import httpClient from "./httpService";
import { checkFaultResponse, FaultModel, faultsResponse, GetConfigAttributeResponse, GetUserDetailsResponse, staticDataResponse } from "../Models/RepairCentreModel";

// const getUserDetailsUrl:any= process.env.REACT_APP_GetUserDetailsUrl;
const getUserDetailsUrl: any = process.env.REACT_APP_userDetailsUrl;
const getConfigAttributeUrl: any = process.env.REACT_APP_getConfigAttributeUrl;
const checkFaultUrl: any = process.env.REACT_APP_CheckFaultValid;
const fetchStaticDataUrl: any = process.env.REACT_APP_RepairCentreStaticData;
const getOpenFaultsUrl: any = process.env.REACT_APP_OpenFaults;
const getClosedFaultsUrl: any = process.env.REACT_APP_ClosedFaults;
const faultApiUrl: any = process.env.REACT_APP_FaultApi;
const faultModelUrl:any=process.env.REACT_APP_getFaultModel;
const environment = process.env.NODE_ENV
const axiosApg1Instance = axios.create();
const axiosApg2Instance = axios.create();
// axiosInstance.defaults.baseURL = process.env.REACT_App_DashBoardUrl;
const DomainInstance = axios.create();
// axiosInstance.defaults.baseURL = process.env.REACT_App_DashBoardUrl;
axiosApg1Instance.defaults.baseURL = process.env.REACT_APP_ApgUrl;
axiosApg2Instance.defaults.baseURL = process.env.REACT_APP_Apg_EE_Url;
axiosApg1Instance.interceptors.request.use(async (config) => {

  if (config.url == "/getaccesstokenvordel" || (!(config.url?.includes(getUserDetailsUrl)))) { return config }

  else {

    const tokenObject = await getAuthTokenForRepairCenter();

    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "Content-Type": "application/json",
      "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
      "UDID": tokenObject.deviceID
    }

    if (tokenObject.accessToken) config.headers.Authorization = `Bearer ${tokenObject.accessToken}`;
    return config;
  }
});
axiosApg2Instance.interceptors.request.use(async (config) => {

  if (config.url == "/getaccesstokenvordel") { return config }


  else {
    let tokenObject: any;
    let sessionTokenObject = JSON.parse(sessionStorage.getItem('MyAccount') || '{}');
    if (sessionTokenObject !== null || sessionTokenObject !== undefined) {
      tokenObject = sessionTokenObject;
    } else {

      tokenObject = await getAuthTokenForRepairCenter();
    }
    config.headers = {
      "APIGW-Tracking-Header": uuidv4(),
      "Content-Type": "application/json",
      "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
      "UDID": tokenObject.deviceID
    }

    if (tokenObject.accessToken) config.headers.Authorization = `Bearer ${tokenObject.accessToken}`;
    return config;
  }
});

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

const repairCentreJourney = {

  //
  getUserDetails: () => httpClient(axiosApg1Instance).get<GetUserDetailsResponse>(getUserDetailsUrl),
  // checkFaultNumber : (param:string) => httpClient(axiosInstance).get<checkFaultResponse>(`${checkFaultUrl}?getenteredNumber=${param}`),
  checkFaultNumber: (param: string) => httpClient(DomainInstance).get<checkFaultResponse>(checkFaultUrl, {
    params: { "enteredNumber": param },
  }),
  fetchStaticData: () => httpClient(DomainInstance).get<staticDataResponse>(fetchStaticDataUrl, {
    params: { "v": "2022-05-09-17-23-34" },
  }),
  getConfigAttributes: () => httpClient(DomainInstance).get<GetConfigAttributeResponse>(getConfigAttributeUrl),
  // getOpenFaults:(params:any)=>httpClient(axiosInstance).get<faultsResponse>(getOpenFaultsUrl+"/"+params.groupKey,{
  //   params:{"pageSize":5,"index":1,"tabId":1}
  // }),
  // getClosedFaults:(params:any)=>httpClient(axiosInstance).get<faultsResponse>(getClosedFaultsUrl+"/"+params.groupKey,{
  //   params:{"pageSize":5,"index":1,"tabId":2}
  // })
  getOpenFaults: (groupData: any, tabId: any) =>
    httpClient(axiosApg2Instance)

      .get<faultsResponse>((environment == 'development') ? getOpenFaultsUrl : faultApiUrl.replace('{groupId}', groupData.selectedGroup.Key)

        .replace('{pageSize}', groupData.pageSize.toString())

        .replace('{index}', groupData.index.toString())

        .replace('{tabId}', tabId)),
  getClosedFaults: (groupData: any, tabId: any) =>
    httpClient(axiosApg2Instance)
      .get<faultsResponse>((environment == 'development') ? getClosedFaultsUrl : faultApiUrl.replace('{groupId}', groupData.selectedGroup.Key)

        .replace('{pageSize}', groupData.pageSize.toString())

        .replace('{index}', groupData.index.toString())

        .replace('{tabId}', tabId)),
  getFaultModel: (faultId:string,customerId:string) => 
  httpClient(DomainInstance)
  .get<FaultModel>((environment == 'development') ? faultModelUrl : faultModelUrl.replace('{faultId}',faultId).replace('{customerId}',customerId)),

};

// export default function Apicall(url:any, method:any){

//     return axios({
//         method: method,
//         url: url,
//         headers: { 
//         "APIGW-Tracking-Header" : uuidv4(),
//         "Content-Type": "application/json",
//         "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
//         "UDID":accessTokendetails.deviceID,
//         Authorization : `Bearer ${accessTokendetails.accessToken}`
//         }
//     })
// }
export default repairCentreJourney;



