import axios, { AxiosError, AxiosResponse } from "axios";
import { history } from "../../..";

import {
  ValidateProfileApiResponse,
  ClientCharacteristicApiBody,
  ClientCharacteristicApiResponse,
  TriggerKCIResponse,
  UpdatePasswordApiBody,
  UpdatePasswordApiResponse,
  UserNameResponse,
  SendVerificationMailResponse,
  RequestProfileWithDetails,
  AuthRequestApiResponse,
  AuthRequestBody,
  ProfileDetailsApiResponse,
} from "../../models/assistedJourneyModels/AssistedJourneyModel";
import Constants from "../../models/constants";
import { store } from "../../stores/store";
import agent from "../agent";
import httpClient from "../agent";

import { getAuthToken, getAuthTokenForAssistedJourney } from "../authToken";

const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_APIGEE_UserProfileAPIBaseURL;

instance.interceptors.request.use(async (config) => {
  if (config.url == "/getaccesstokenvordel") return config;
  config.headers = {
    "APIGW-Tracking-Header": uuidv4(),
    "Content-Type": "application/json",
    "APIGW-Client-Id": process.env.REACT_APP_APGIW_ClientId,
    "asp-session-id": store.commonStore.SessionId
      ? store.commonStore.SessionId
      : "",
    UDID: store.commonStore.udid ? store.commonStore.udid : "",
  };
  if (store.commonStore.accessToken)
    config.headers.Authorization = `Bearer ${store.commonStore.accessToken}`;
  return config;
});

instance.interceptors.response.use(
  async (response) => {
    //await sleep(1000);
    store.commonStore.setAPIError("APIERROR", "");
    return response;
  },
  (error: AxiosError) => {
    const { data, status, config } = error.response!;

    store.commonStore.setAPIError("APIERROR", Constants["APIERRORMESSAGE"]);
    switch (status) {
      case 400:
        if (config.method === "get" && data.errors?.hasOwnProperty("id")) {
          //  history.push("/not-found");
        }
        if (data.errors) {
          const modalStateErrors = [];
          for (const key in data.errors) {
            if (data.errors[key]) {
              modalStateErrors.push(data.errors[key]);
            }
          }
          throw modalStateErrors.flat();
        } else {
          //  toast.error(data);
        }
        break;

      case 401:
        //   toast.error("unauthorized");
        console.log("401 UNAUTHORIZED");
        store.commonStore.sessionExpired= true;

        break;

      case 404:
        // history.push("/not-found");

        break;

      case 500:
        //    history.push("/server-error");
        break;
    }

    return Promise.reject(error);
  }
);

const assistedJourney = {
  profileDetails: () =>
    httpClient(instance).get<ProfileDetailsApiResponse>("/profile-details"),

  triggerKci: () =>
    httpClient(instance).get<TriggerKCIResponse>("/resend-onoff-code"),

  validateClientCharacteristic: (
    clientCharacteristicApiBody: ClientCharacteristicApiBody
  ) =>
    httpClient(instance).post<ClientCharacteristicApiResponse>(
      "/validate-security",
      clientCharacteristicApiBody
    ),

  updatePassword: (updatePasswordApiBody: UpdatePasswordApiBody) =>
    httpClient(instance).post<UpdatePasswordApiResponse>(
      "/update-profile",
      updatePasswordApiBody
    ),
};

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export default assistedJourney;
