import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import CUGSelection from '../CUGSelection';
import './style.scss';
import { connect } from 'react-redux';
import { FetchUserDetails } from '../../../../../app/redux/actions/repairCenter-action';
// import {fetchuserdetails} from "./../../../../../app/redux/action-data-fetch/getuserdetail";
interface Props {
  data: any;
}
const MainNavigation = (props:Props) => {

  let navItems = [
    {
      title: "My Account",
      url: "/Account/Dashboard#/",
      icon: "home"
    },
    {
      title: "Billing",
      url: "/Account/LoginRedirect.aspx?tabId=1",
      icon: "bill"
    },
    {
      title: "Orders",
      url: "/Account/Dashboard#/order-tracker",
      icon: "delivery"
    },
    {
      "title": "Services",
      "url": "/Account/Dashboard#/services",
      "icon": "ABN"
    },
    {
      title: "Faults",
      // url: "/Account/Dashboard#/RepairCentre",
      url: "/Account/RepairCentre",
      icon: "fault"
    },
    {
      title: "Roles",
      url: "/Account/Dashboard#/user-roles",
      icon: "users"
    },
    {
      title: "Messages",
      url: "/Account/Dashboard#/messages",
      icon: "messages"
    },
    {
      title: "Profile",
      url: "/Account/Dashboard#/profile",
      icon: "profile"
    },
    {
      title: "Email",
      url: "https://portal.microsoftonline.com/",
      icon: "mail"
    }
  ]
      const currLocation = useLocation();
      const history = useHistory();
  
        
      if(window.location.hash.includes("#/RepairCentre")){
      
      currLocation.pathname = history.location.pathname+history.location.hash;
    currLocation.hash="";
    history.location.hash="";
      // history.push('/Account/RepairCentre')
      //  console.log(history.location)
    }

      // if(currLocation.hash){
      //   currLocation.pathname = "
      // }
      const currPage = 'page' + currLocation.pathname.replace(/[^A-Z0-9]+/ig, "-").toLowerCase();
      document.body.classList.add(currPage);
      setTimeout(() => {
        const activeMenuItm:any = document.querySelector('.active-nav-item-caption span');
        activeMenuItm.innerText = document.querySelector('.nav-item.bt-active')?.textContent;
      }, 0);

      const [isActive, setActive] = useState(false);

      const toggleMenu = () => {
        setActive(!isActive);
      };
    return (
        <>
            <div className="main-nav-wrp" id="mainNavigation">
                <div className="main-nav app-container pos-r">
                    <div className="main-nav-items-wrp" >
                    <span className={`active-nav-item-caption ${isActive ? "bt-active" : ''}`} onClick={toggleMenu} ><span></span><i className="bt-arrow-down bt-icon black"></i></span>
                        <div className={`main-nav-items-wrp ${isActive ? "bt-active" : ''}`}>
                          <div className={`main-nav-items ${isActive ? "bt-active" : ''}`}>
                              {navItems.map((navItem: any) => (                               
                                  <span key={navItem.title} className={`nav-item nav-itm-${navItem.title.replace(/[^A-Z0-9]+/ig, "-").toLowerCase()} ${currLocation.pathname.toLowerCase() === navItem.url.toLowerCase() ? 'bt-active':''} `}>
                                      <a href={navItem.url}><i className={`mobile-only nav-item-icon ma-icon ma-${navItem.icon}`}></i>{navItem.title}</a>
                                  </span>
                              ))}
                          </div>
                        </div>
                    </div>
                    <CUGSelection data={props.data && props.data.data}/>
                </div>
            </div>
        </>
    )
}

// export default MainNavigation;
const mapStateToProps = (state:any) =>{
  return{
      data:state.fetchUserDetails,
  }
}

const mapDispatchToProps = (dispatch:any) =>{
  return{
    FetchUserDetails:() => dispatch(FetchUserDetails()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);