import repairCentreJourney from './../../redux/api/Services/apiService';
import { ADD_ERROR, CHANGE_CUG_SELECTION, FETCH_CLOSED_FAULTS, FETCH_OPEN_FAULTS, FETCH_USER_DETAILS, GET_CONFIG_ATTRIBUTES, REPAIR_CENTRE_STATIC_DATA } from '../action-types/repairCenter-action-type';
import DateService from '../../../services/DateFormatter';

export const FetchUserDetails = () => {

    return (dispatch: any) => {
        repairCentreJourney.getUserDetails().then((res) => {


            dispatch({ type: FETCH_USER_DETAILS, data: res })

        })
            .catch((error) => {
                console.log("response", error);
            })



    }

};
export const GetConfigAttributes = () => {

    return (dispatch: any, getState: any) => {
        if (Object.keys(getState().getConfigAttributes.config).length === 0) {


            repairCentreJourney.getConfigAttributes().then((res) => {


                dispatch({ type: GET_CONFIG_ATTRIBUTES, data: res })

            })
                .catch((error) => {
                    console.log("response", error);
                })

        }

    }

};
export const FetchOpenFaults = (groupData: any, tabId: any) => {

    return (dispatch: any) => {
        repairCentreJourney.getOpenFaults(groupData, tabId).then((res) => {
            const faults = res;
            faults.loaded = {};
            faults.loaded[groupData.selectedGroup.ContactId] = true;
            // faults.result.Faults.map((fault) => fault.contactId = groupData.selectedGroup.ContactId);
            faults.result.Faults.map((fault) => {           
                fault.contactId = groupData.selectedGroup.ContactId;
               return formatStatus(fault);
            });
            dispatch({ type: FETCH_OPEN_FAULTS, data: faults })
        })
            .catch(err => {
                dispatch({ type: ADD_ERROR, error: err });
            })


    }

};
export const FetchClosedFaults = (groupData: any, tabId: any) => {

    return (dispatch: any) => {
        repairCentreJourney.getClosedFaults(groupData, tabId).then((res) => {
            const faults = res;
            faults.loaded = {};
            faults.loaded[groupData.selectedGroup.ContactId] = true;
            // faults.result.Faults.map((fault) => fault.contactId = groupData.selectedGroup.ContactId);
            faults.result.Faults.map((fault) => {           
                fault.contactId = groupData.selectedGroup.ContactId;
                return formatStatus(fault);
            });
            faults.result.Faults = faults.result.Faults.filter(function (item: any) {

                if (!item.isOpen && (item.ReportedOn !== null || item.ReportedOn !== "")) {
                    const ReportedOn = new Date(
                        item.ReportedOn
                    );
                    let currentdate = new Date();
                    let last3months = new Date(currentdate.setMonth(currentdate.getMonth() - 3));
                    return (
                        ReportedOn > last3months
                    );
                }

            })


            dispatch({ type: FETCH_CLOSED_FAULTS, data: faults })

        })
            .catch(err => {

                dispatch({ type: ADD_ERROR, error: err });
            })


    }

};
// export const CheckFaultNumber=(params:any)=>{
//     return (dispatch:any) => {
//         repairCentreJourney.checkFaultNumber(params).then((res)=>{


//                 dispatch({ type: CHECK_FAULT_VALID,  data: res})

//             })
//             .catch((error)=>{
//              console.log("response", error);
//              })



//        }
// }
export const FetchStaticData = () => {
    return (dispatch: any) => {
        repairCentreJourney.fetchStaticData().then((res) => {


            dispatch({ type: REPAIR_CENTRE_STATIC_DATA, data: res })

        })
            .catch((error) => {
                console.log("response", error);
            })



    }
}
export const ChangeCug = (selectedCug: any) => {
    return (dispatch: any) => {



        dispatch({ type: CHANGE_CUG_SELECTION, data: selectedCug })






    }
}

function formatStatus(fault: any) {
    /*
    if (fault.Status.toUpperCase() == 'FAULT OPEN' || fault.Status.toUpperCase() == 'FAULT CLEARED' || fault.Status.toUpperCase() == 'FAULT IN PROGRESS') {
       
        fault.Status = 'In progress';
    }
    if (fault.Status.toUpperCase() == 'FAULT RESOLVED') {
     
        fault.Status = 'Closed';
    }
    if (fault.Status.toUpperCase() == 'FAULT PARKED' || fault.Status.toUpperCase() == 'INCOMPLETE FAULT') {
    
        fault.Status = 'Action needed';
    }
    */
    return fault;
}
