import { BrowserRouter, Route, Switch } from "react-router-dom";
import React, { useEffect } from "react";
import { Preloader } from "../../../bt-components/Preloader";
import DecibelAnalytics from "../../../analytics/decibelAnalytics";


const NewHubPage = React.lazy(
    () =>
        import(
            "../NewHubPages"
        )
);

const NewHubPageApp = () => {


    return (
        <>
           <DecibelAnalytics />
            <Switch>
                <React.Suspense fallback={<Preloader />}>
                {(document.body.classList.add('page-hub')) }
                         <Route
                         exact
                        path={["/","/hub","/Hub","/Hub/","/hub/","/Hub/*","/hub/*"]}
                        component={NewHubPage} />
                </React.Suspense>
            </Switch>
        </>
    );
}

export default NewHubPageApp;