import { makeAutoObservable, reaction, runInAction } from "mobx";
import { getAuthTokenForAssistedJourney } from "../api/authToken";
import { GetaccesstokenvordelResponseForAssisted } from "../models/assistedJourneyModels/AssistedJourneyModel";
import Constants from "../models/constants";
import { store } from "./store";

export default class CommonStore {
  apiError: any;
  apiErrorTry: number = 0;
  apiErrorTryList: any;

  token: string | null = window.localStorage.getItem("jwt");
  tokenExpiry: Date | null = window.localStorage.getItem("jwt-expiry")
    ? new Date(window.localStorage.getItem("jwt-expiry") as string)
    : null;

  SessionId: string | null = window.localStorage.getItem("asp-session-id");
  ResetPasswordId: string | null =
    window.localStorage.getItem("reset-password-id");

  appLoaded = false;
  isLoading = false;
  sessionExpired: boolean =false;


  getAuthTokenResponse: GetaccesstokenvordelResponseForAssisted | undefined =
    undefined;
  validateProfileDetailsError: string = "";
  accessToken: string | null = "";
  udid: string | null = "";

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.token,
      (token) => {
        if (token) window.localStorage.setItem("jwt", token);
        else {
          window.localStorage.removeItem("jwt");
          window.localStorage.removeItem("jwt-expiry");
        }
      }
    );
    reaction(
      () => this.SessionId,
      (SessionId) => {
        if (SessionId) {
          window.localStorage.setItem("asp-session-id", SessionId);
          console.log("SESSION ID : " + SessionId);
        } else {
          window.localStorage.removeItem("asp-session-id");
        }
      }
    );
    reaction(
      () => this.ResetPasswordId,
      (ResetPasswordId) => {
        if (ResetPasswordId) {
          window.localStorage.setItem("reset-password-id", ResetPasswordId);
          console.log("Reset Password Id : " + ResetPasswordId);
        } else {
          window.localStorage.removeItem("reset-password-id");
        }
      }
    );

    reaction(
      () => this.tokenExpiry,
      (tokenExpiry) => {
        if (tokenExpiry)
          window.localStorage.setItem("jwt-expiry", tokenExpiry.toISOString());
        else {
          window.localStorage.removeItem("jwt");
          window.localStorage.removeItem("jwt-expiry");
        }
      }
    );
  }

  setAPIError = (errorKey: string, error: string) => {
    this.apiError = { ...this.apiError, [errorKey]: error };
    if (error === "")
      this.apiErrorTryList = { ...this.apiErrorTryList, [errorKey]: 0 };
    else
      this.apiErrorTryList = {
        ...this.apiErrorTryList,
        [errorKey]: this.apiErrorTryList[errorKey]
          ? this.apiErrorTryList[errorKey] + 1
          : 1,
      };
    this.apiErrorTry = this.apiErrorTryList[errorKey];
  };
  emptyAPIError = () => {
    this.apiError = {};
    this.apiErrorTry = 0;
    this.apiErrorTryList = {};
  };
  setToken = (token: string | null) => {
    this.token = token;
  };

  setApploaded = () => {
    this.appLoaded = true;
  };
  setIsLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  // AUTH TOKEN FUNCTION FOR ASSISTED JOURNEY
  getAuthToken = async () => {
    runInAction(() => {
      this.getAuthTokenResponse = undefined;
    });
    getAuthTokenForAssistedJourney()
      .then((m) => {
        runInAction(() => {
          this.getAuthTokenResponse = m;
          console.log(m);
          this.accessToken = this.getAuthTokenResponse.accessToken;
          this.udid = this.getAuthTokenResponse.deviceID;

          console.log("UDID: " + this.udid);
        });

        store.commonStore.setAPIError("VALIDATEPROFILEDETAILS", "");
      })
      .catch((err) => {
        runInAction(() => {
          this.validateProfileDetailsError = Constants["APIERRORMESSAGE"];
        });
        store.commonStore.setAPIError(
          "VALIDATEPROFILEDETAILS",
          Constants["APIERRORMESSAGE"]
        );
        console.log(err);
      });
  };
}
