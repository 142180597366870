import { combineReducers } from "redux";

import { displayError, fetchFaults, fetchStaticData, fetchUserDetails, getConfigAttributes, selectedGroup } from "./Repaircenter";

const rootReducer = combineReducers({
    fetchUserDetails,
    fetchStaticData,
    fetchFaults,
    selectedGroup,
    getConfigAttributes,
    displayError
});

export default rootReducer;