import "./App.scss";

import { GetInTouchModal } from "./features/RecoveryJourneyPages/RecoveryJourneyComponents/GetInTouchModal";
import { CancelAccount } from "./features/modules/SelfRegistrationJourneyPages/AddAccount/CancelAccountPopup";
import CancelAccountAccess  from "./features/modules/SelfRegistrationJourneyPages/AccountAccess/CancelAccountAccessPopup";

import {PaperFreeBillingCancleAccount} from "./features/modules/SelfRegistrationJourneyPages/PaperFreeBilling/PaperFreePopup";

import { Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import React from "react";
import AssistedJourneyApp from "./features/modules/AssistedJourneyPages/AssistedJourneyApp";
import { useEffect, useState } from "react";
import InterceptApp from "./features/modules/Intercept/InterceptApp";
import MyAccountApp from "./features/modules/MyAccount/MyAccountApp";
import { Preloader } from "./bt-components/Preloader";
import ReviewContractApp from "./features/modules/ReviewContract/ReviewContractApp";

import UpdateContact from "./features/modules/Intercept/UpdateContact/UpdateContact";
import SecurityCheck from "./features/modules/AssistedJourneyPages/SecurityCheck/SecurityCheck";
import DecibelAnalytics from "./analytics/decibelAnalytics";

import SelfRegistrationApp from "./features/modules/SelfRegistrationJourneyPages/SelfRegistrationApp";

import NewHubPageApp from "./features/modules/NewHubPages/NewHubPageApp";
import GetPaperFreeFlagApp from "./features/modules/SelfRegistrationJourneyPages/GetPaperFreeFlagPage/GetPaperFreeFlagPageApp";
import EmailNotVerifiedApp from "./features/modules/NewHubPages/EmailNotVerifiedPages/EmailNotVerifiedPageApp";



const VerifySecurityNumber = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/VerifySecurityNumber"
    )
);

const ContactUs = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/TechnicalErrorPage"
    )
);

const GetUserProfiles = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/GetUserProfiles/GetUserProfiles"
    )
);

const PinVerificationDetails = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/PinVerificationDetails"
    )
);
const RecoverLoginDetailsSuccess = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/RecoverLoginDetailsSuccess"
    )
);
const SecurityQuestionDetails = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/SecurityQuestionDetails"
    )
);
const RecoverLogin = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/RecoverLogin"
    )
);
const TechnicalErrorPage = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/TechnicalErrorPage"
    )
);

const CantRememberSN = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/CantRememberSN"
    )
);
const NewPasswordSet = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/NewPasswordSet"
    )
);
const PasswordSuccessfullyChanged = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/PasswordSuccessfullyChanged"
    )
);
const SessionExpired = React.lazy(
  () =>
    import(
      "./features/RecoveryJourneyPages/RecoveryJourneyComponents/SessionExpired"
    )
);

function App() {
  const history = useHistory();
  const location = useLocation();
  const [isAssisted, setIsAssisted] = useState(false);
  const [isSelfRegistration, setIsSelfRegistration] = useState(false);
  const [isEmailNotVerified, setIsEmailNotVerified] = useState(false);
  const [isOnlyVerifyEmail, setIsOnlyVerifyEmail] = useState(false);

  useEffect(()=> {
    if (location.pathname.toLowerCase().indexOf('registration') !== -1) {
      setIsSelfRegistration(true);
    }

    if (location.pathname.toLowerCase().includes('emailnotverified') ) {
      setIsEmailNotVerified(true);
    }

    if (location.pathname.toLowerCase().includes('verifyemail') && !location.pathname.toLowerCase().includes('emailnotverified')) {
      setIsOnlyVerifyEmail(true);
    }

    if (location.pathname.indexOf('Account/Intercept') !== -1) {
      setIsAssisted(true);
    }
  }, [location])
  const { sessionId }: any = useParams()
  useEffect(() => {

    if (window.location.hash.includes("#/resetPassword")) {
      const c = window.location.hash.split('/')
      console.log(window.location);
      console.log(sessionId);
      console.log(c[2]);
      history.push(`/ForgotUsernameandPassword/resetPassword/${c[2]}`)
      console.log(history.location)
    }
    if (window.location.hash.includes("#/RepairCentre")) {
      history.push({
        pathname: history.location.pathname + history.location.hash,
      })
      history.location.hash = "";
      // history.push('/Account/RepairCentre')

    }
    if(location.pathname.includes("/EECCComplience")){
      const c = location.pathname;
      history.push(`/EECCComplience/${c.substring(c.lastIndexOf('/') + 1)}`);
    }

    if(location.pathname.includes("/VerifyEmail") && !location.pathname.includes("/EmailNotVerified")){
      const c = location.pathname;
      history.push(`/VerifyEmail/${c.substring(c.lastIndexOf('/') + 1)}`);
    }
  }, [])

  return (
    <>

      {isAssisted && <AssistedJourneyApp />}
      <InterceptApp />
      <MyAccountApp />
      <DecibelAnalytics />
      <ReviewContractApp />

     {isOnlyVerifyEmail &&  <GetPaperFreeFlagApp /> }
     {isEmailNotVerified &&  <EmailNotVerifiedApp />}

      <NewHubPageApp />

      <Switch>
        <React.Suspense fallback={<Preloader />}>
          <Route
            exact
            path="/UpdateContactIntercept"
            component={UpdateContact}
          ></Route>
          <Route
            exact
            path={["/ForgotUsernameandPassword/resetPassword/:sessionId", "/ForgotUsernameandPassword#/resetPassword/:sessionId"]}
            component={NewPasswordSet}
          ></Route>
          <Route
            exact
            path="/ForgotUsernameandPassword#/recoverLogin"
            component={RecoverLogin}
          ></Route>
          <Route exact path="ForgotUsernameandPassword#/recoverAccount">
            <RecoverLogin isCompromisedRoute={true} />
          </Route>
          <Route
            exact
            path="/ForgotUsernameandPassword#/securityNumber"
            component={VerifySecurityNumber}
          ></Route>

          <Route
            exact
            path="/ForgotUsernameandPassword#/getProfiles"
            component={GetUserProfiles}
          ></Route>

          <Route
            exact
            path="/ForgotUsernameandPassword#/securityQuestion"
            component={SecurityQuestionDetails}
          ></Route>
          <Route
            exact
            path="/ForgotUsernameandPassword#/verifyPin"
            component={PinVerificationDetails}
          ></Route>

          <Route
            exact
            path="/ForgotUsernameandPassword#/contact"
            component={ContactUs}
          ></Route>

          <Route
            exact
            path="/ForgotUsernameandPassword#/recoverLoginSuccess"
            component={RecoverLoginDetailsSuccess}
          ></Route>

          <Route
            exact
            path="/ForgotUsernameandPassword#/technicalError"
            component={TechnicalErrorPage}
          ></Route>
          <Route
            exact
            path="/ForgotUsernameandPassword#/CantRememberSN"
            component={CantRememberSN}
          ></Route>

          <Route
            exact
            path="/ForgotUsernameandPassword#/PasswordSuccessfullyChanged"
            component={PasswordSuccessfullyChanged}
          ></Route>
          <Route
            exact
            path="/ForgotUsernameandPassword/SessionExpired"
            component={SessionExpired}
          ></Route>

          <Route
            exact
            path="/ForgotUsernameandPassword"
            component={RecoverLogin}
          ></Route>
          <Route
            exact
            path={[
              "/ForgotUserName",
              "/ForgotUserName/*",
              "/ForgotPassword",
              "/ForgotPassword/*",
            ]}
            component={RecoverLogin}
          ></Route>
        </React.Suspense>
      </Switch>
      <GetInTouchModal />
      <CancelAccount />
      <PaperFreeBillingCancleAccount />
      <CancelAccountAccess />
      {isSelfRegistration && <SelfRegistrationApp />}
    </>
  );
}

export default App;
